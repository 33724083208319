
            .mdc-list-item__primary-text {
                height: 100%;
                width: 100%;
            }

            .mat-mdc-option {
                height: fit-content !important;
                width: 100%;
            }
        